<template>
  <div id="contact" class="contact section">
    <div class="wrap flex row justify-center">
      <div class="cl1">
        <p>
          <strong>>> Contact</strong>
          <br>
          <br>
          Vous avez besoin de l'intervention de Chauffe Marcel dans la région de Bruxelles ? Vous souhaitez vous regrouper avec des voisins et profiter de nos prix dégressifs ? Vous habitez en périphérie Bruxelloise et vous ne parvenez pas à rentrer votre adresse ?  Vous souhaitez remplacer votre chaudière/boiler ? <b>Contactez-nous via le formulaire ci-dessous pour toutes vos questions, nous vous répondrons dans les plus brefs délais.</b>
        </p>
        <br>
        <q-form
              v-if="!success && !error"
              @submit="onSubmit"
              class="form"
            >
              <q-input
                v-model="name"
                label="Nom *"
                lazy-rules
                :rules="[ val => val && val.length > 0 || 'Entrez votre nom']"
              />

              <q-input
                v-model="mail"
                type="email"
                label="Email *"
                lazy-rules
                :rules="[ val => val && val.length > 0 || 'Entrez une adresse email']"
              />

              <q-input
                v-model="phone"
                type="tel"
                label="Téléphone *"
                lazy-rules
                :rules="[ val => val && val.length > 0 || 'Entrez un numéro de téléphone']"
              />

              <q-input
                v-model="message"
                type="textarea"
                rows="4"
                maxlength="500"
                label="Message *"
                lazy-rules
                :rules="[ val => val && val.length > 0 || 'Entrez votre demande ou question']"
              />
              <br>
              <q-btn label="Envoyer" type="submit" color="primary"/>
        </q-form>
        <div class="success" v-if="success && !error">
          Merci !<br>
          Votre message a bien été envoyé, nous vous recontacterons dans les plus brefs délais.
        </div>

        <div class="error" v-if="error">
          Une erreur c'est produite...<br>
          Merci de réessayer plus tard.
          <br><br>
          <q-btn label="Réessayer" class="retry" @click="retry" color="white"/>
        </div>
      </div>

      <!-- <div class="cl2">
        <p>
          <br><br>
          <strong>Horaires</strong>
          <br>
          Du lundi au vendredi : de 8h à 19h<br>
          Samedi : 10h à 18h<br>
          Dimanche : fermé<br>
          <br>
          <strong>Zone d'activité</strong>
          <br>
          Nous sommes à votre disposition sur tout le territoire d'Anderlecht et sa périphérie !
        </p>
      </div> -->
    </div>
    <footer>
      <a href="https://www.chauffemarcel.be/api/privacy-policy/" target="_blank">
      Copyright © Chauffe Marcel 2022 Tous droits réservés - Paiement - CGV - Mentions légales - Conditions d'utilisations
      </a>
    </footer>
  </div>
</template>

<style scoped lang="scss">
  @import '~Styles/quasar.variables.scss';

  .section {
    min-height: 100vh;
  }
  strong {
    color: $primary;
  }
  .cl1{
    width: 450px;
    max-width: 100%;
    margin: auto;
  }
  .cl1 p{
    max-width: 450px;
  }

  .cl1 .form{
    max-width: 450px;
  }

  .success, .error {
    background-color: $info;
    color: white;
    max-width: 450px;
    padding: 25px;
    border-radius: 25px;
  }

  .error{
    background-color: $negative;
  }

  .retry{
    color: $negative !important;
  }

  .cl2{
    font-size: 1rem;
    font-weight: 300;
    max-width: 260px;
  }

  @media only screen and (max-width: 635px) {
    .cl1 .form{
      max-width: 100%;
    }
  }

  @media only screen and (max-width: 1084px) {
    .wrap {
      justify-content: left;
    }
    .cl2{
      max-width: 100%;
    }
  }

  footer {
    padding-top: 100px;
    margin-top: auto;
    text-align: center;
  }
  footer a{
    text-decoration: none;
    color: #535353;
    font-size: 0.8rem;
    line-height: 100%;
  }
</style>

<script>
  function fixedEncodeURIComponent (str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, escape);
  }

  export default{
    data(){
      return {
        name: '',
        mail: '',
        phone: '',
        message: '',
        success: false,
        error: false,
      }
    },
    methods : {
      onSubmit : function(){

        let send = encodeURI( JSON.stringify( {
          name : fixedEncodeURIComponent( this.name ),
          mail : this.mail,
          phone : this.phone,
          message : fixedEncodeURIComponent( this.message ),
        }))

        
        this.$wp.contact = this.$wp.registerRoute( 'chauffeMarcel/v2', '/contact/(?P<send>)' );

        this.$wp.contact().send( send ).then( ( response ) => {
            
            console.log('mail', response);
            
            if( response == false ) 
            {
              this.error = true
            }
            else
            {
              //this.success = true
            }
        });
      },
      retry : function(){
        this.error = false
      }
    }
  }
</script>