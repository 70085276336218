<template>
  <q-layout view="lHh Lpr lFf">
    <q-page-container>
      <router-view v-slot="{ Component }">
        <transition name="pages" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </q-page-container>
  </q-layout>
</template>

<style>
  
</style>

<script>

export default {
  name: 'LayoutDefault',
  components: {
    
  },
}
</script>
