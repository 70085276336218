<template>
  <div class="steps section">
    <div class="wrap">
      <h2>Quelles sont les <span>différentes étapes ?</span></h2>
      <div class="flex column justify-center">
        <div class="step flex row items-center">
          <div class="fire-next" v-scroll-fire="scroll_appear"></div>
          <div class="number flex row justify-center items-center">1</div>
          <p>Vous complétez notre formulaire de demande d'entretien de chaudière en ligne.</p>
          <div class="fire-prev" v-scroll-fire="scroll_appear"></div>
        </div>
        <div class="step flex row items-center">
          <div class="fire-next" v-scroll-fire="scroll_appear"></div>
          <div class="number flex row justify-center items-center">2</div>
          <p>Nous vous contactons dans les 3 jours ouvrables afin de trouver une plage horaire qui vous convient.</p>
          <div class="fire-prev" v-scroll-fire="scroll_appear"></div>
        </div>
        <div class="step flex row items-center">
          <div class="fire-next" v-scroll-fire="scroll_appear"></div>
          <div class="number flex row justify-center items-center">3</div>
          <p>Notre technicien agréé se rends chez vous à vélo</p>
          <div class="fire-prev" v-scroll-fire="scroll_appear"></div>
        </div>
        <div class="step flex row items-center">
          <div class="fire-next" v-scroll-fire="scroll_appear"></div>
          <div class="number flex row justify-center items-center">4</div>
          <p>Dès l'entretien réalisé vous recevez votre attestation directement sur votre adresse email, pas de frais cachés, pas de liquide, pas de surprises.</p>
          <div class="fire-prev" v-scroll-fire="scroll_appear"></div>
        </div>
        <div class="step flex row items-center">
          <div class="fire-next" v-scroll-fire="scroll_appear"></div>
          <div class="number flex row justify-center items-center">5</div>
          <p>Pas besoin de s'en faire, nous nous occupons de vous rappeler quand il sera temps pour un nouveau contrôle !</p>
          <div class="fire-prev" v-scroll-fire="scroll_appear"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .steps{
    overflow: hidden;
  }
  .step{
    transition: all 0.5s;
    opacity: 0;
    width: 500px;
    max-width: 100%;
    min-height: 120px;
    border: solid 1px;
    border-radius: 25px;
    padding: 25px 50px 20px 65px;
    margin: auto;
    margin-bottom: 30px;
  }

  .fire-next{
    position: absolute;
    margin-top: -100px;
  }

  .fire-prev{
    position: absolute;
    margin-top: 100px;
  }

  .step:nth-child(even){
    transform: translateX(-250px);   
  }
  .step:nth-child(odd){
    transform: translateX(250px);
  }
  .step.visible{
    opacity: 1;
    transform: translateX(0px);
  }
  .number{
    text-align: center;
    position: absolute;
    background-color: #B7B7B7;
    margin-left: -100px;
    width: 75px;
    height: 75px;
    border-radius: 50px;
    font-size: 50px;
    font-weight: bold;
    padding-bottom: 9px;
    color: white;
  }

  $step-1: #59D19B;
  $step-2: #3BAC79;
  $step-3: #249260;
  $step-4: #1E784F;
  $step-5: #145236;

  .step:nth-child(1){ border-color: $step-1; }
  .step:nth-child(1) .number{ background-color: $step-1; }

  .step:nth-child(2){ border-color: $step-2; }
  .step:nth-child(2) .number{ background-color: $step-2; }

  .step:nth-child(3){ border-color: $step-3; }
  .step:nth-child(3) .number{ background-color: $step-3; }

  .step:nth-child(4){ border-color: $step-4; }
  .step:nth-child(4) .number{ background-color: $step-4; }

  .step:nth-child(5){ border-color: $step-5; }
  .step:nth-child(5) .number{ background-color: $step-5; }

  @media only screen and (max-width: 635px) {
    .section .wrap {
      padding: 0px 20px;
    }
    .step {
      width: 100%;
      padding: 35px 25px 20px 25px;
      margin-top: 50px;
    }
    .number{
      position: relative;
      margin-top: -80px;
      margin-bottom: 15px;
      margin-left: 0px;
    }
  }

</style>

<script>
  import $ from 'jquery'

  export default {
    methods : {
      scroll_appear : function(el) {
        $(el).parent().addClass('visible')
      },
      scroll_appear_before : function (el) {
        $(el).parent().addClass('visible')
      }
    }
  }
</script>