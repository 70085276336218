import * as VueRouter from 'vue-router'
import Home from 'Page/Home'
import Demander_un_entretien from 'Page/DemanderUnEntretien'

const router = VueRouter.createRouter({
	history: VueRouter.createWebHistory(),
	routes: [
		{
			path: '/',
			name: 'home',
			component: Home,
		},
		{
			path: '/demander-un-entretien',
			name: 'Demander un entretien',
			component: Demander_un_entretien
		},
	],
	scrollBehavior(to, from, savedPosition) {
		console.log( savedPosition )
		if (savedPosition) {
			return savedPosition
		} else {
			return { top: 0 }
		}
	},
})

export default router