<template>
  <div class="why section">
    <div class="wrap">
      <h2>Pourquoi choisir <span>Chauffe Marcel ?</span></h2>
      <div class="flex justify-center">
        <q-card class="why-card" >
          <div class="fire-next" v-scroll-fire="scroll_appear"></div>
          <q-card-section>
            <img src="~Assets/chauffe-marcel_01_icone.svg">
            <h3>Rendez-vous<br> 24h/24</h3>
            <p>
              Le système de prise de rendez-vous en ligne est disponible à toute heure du jour et de la nuit.
            </p>
          </q-card-section>
          <div class="fire-prev" v-scroll-fire="scroll_appear_before"></div>
        </q-card>
        
        
        <q-card class="why-card">
          <div class="fire-next" v-scroll-fire="scroll_appear"></div>
          <q-card-section>
            <img src="~Assets/chauffe-marcel_02_icone.svg">
            <h3>Déplacement <br>à vélo</h3>
            <p>
              Fini les camionnettes et la pollution, nous sommes C02 neutral chez Chauffe Marcel.
            </p>
          </q-card-section>
          <div class="fire-prev" v-scroll-fire="scroll_appear_before"></div>
        </q-card>
        
        
        <q-card class="why-card">
          <div class="fire-next" v-scroll-fire="scroll_appear"></div>
          <q-card-section>
            <img src="~Assets/chauffe-marcel_03_icone.svg">
            <h3>Chauffagiste diplômé</h3>
            <p>
              Nos techniciens sont Bruxellois et agréés PEB.
            </p>
          </q-card-section>
          <div class="fire-prev" v-scroll-fire="scroll_appear_before"></div>
        </q-card>
        
        
        <q-card class="why-card">
          <div class="fire-next" v-scroll-fire="scroll_appear"></div>
          <q-card-section>
            <img src="~Assets/chauffe-marcel_04_icone.svg">
            <h3>Service <br>Rapide</h3>
            <p>
              Nous vous contacterons dans les 3 jours ouvrables afin de fixer un rendez-vous.
            </p>
          </q-card-section>
          <div class="fire-prev" v-scroll-fire="scroll_appear_before"></div>
        </q-card>        
      </div>
      <div class="bottom-bt">
        <q-btn to="/demander-un-entretien" color="primary" label="Demander un entretien" />  
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import '~Styles/quasar.variables.scss';

  .section{
    background-color: $bg-grey;
  }
  .why-card{
    transition: all 1s;
    opacity: 0;    
    transform: translateY(50px);
    width: 240px;
    margin: 15px;
    text-align: center;
    box-shadow: 0px 0px 13px -2px rgba(0,0,0,0.10);
    border-radius: 25px;
  }

  .why-card:nth-child(2){
    transform: translateY(100px);
  }

  .why-card:nth-child(3){
    transform: translateY(150px);
  }

  .why-card:nth-child(4){
    transform: translateY(200px);
  }

  .why-card.visible{
    opacity: 1;
    transform: translateY(0px);
  }

  .fire-next{
    position: absolute;
    margin-top: 50px;
  }

  .fire-prev{
    position: absolute;
    margin-top: -50px;
  }

  .why-card .q-card__section{
    padding: 35px 20px;
  }

  .why-card p {
    font-family: "sofia-pro";
    font-size: 1rem;
    font-weight: 300;
  }

  img{
    width: 50%;
  }

  .bottom-bt{
    padding-top: 30px;
    text-align: center;
  }

  @media only screen and (max-width: 635px) {
    .why-card{
      width: 100%;
      transform: translateY(50px);
    }
  }
</style>

<script>
import $ from 'jquery'

export default {
  methods : {
    scroll_appear : function(el) {
      $(el).parent().addClass('visible')
    },
    scroll_appear_before : function (el) {
      $(el).parent().addClass('visible')
    }
  }
}
</script>