import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';import Meta from 'quasar/src/plugins/Meta.js';
import quasarUserOptions from './quasar-user-options'
import router from 'Objects/router'
import WPAPI from 'wpapi'
import VueGtag from 'vue-gtag';


let wp = null;

if ( process.env.NODE_ENV === 'development' )
{
	wp = new WPAPI({ endpoint: 'http://localhost/chauffe-marcel/api/wp-json' });
}
else
{
	wp = new WPAPI({ endpoint: 'https://www.chauffemarcel.be/api/wp-json' });
}

const app = createApp(App)

app.config.globalProperties.$wp = wp;

app.use(router)

// Intégration de Quasar
app.use(Quasar, {
  plugins: {
    Meta
  }
})

// Intégration de vue-gtag
app.use(VueGtag, {
  config: { id: 'AW-11350601624' }
}, router)


app.use(Quasar, quasarUserOptions).mount('#app')
