<template>
  <q-page class="home">
    <Intro/>
    <Why/>
    <Steps/>
    <Faq/>
    <Cta/>
    <Contact/>
  </q-page>
</template>

<style>
</style>

<script>
import Intro from 'Section/home/Intro'
import Why from 'Section/home/Why'
import Steps from 'Section/home/Steps'
import Faq from 'Section/home/Faq'
import Cta from 'Section/home/Cta'
import Contact from 'Section/home/Contact'
import { useMeta } from 'quasar'


export default {
  components: {
    Intro,
    Why,
    Steps,
    Faq,
    Cta,
    Contact
  },
  setup (){

    useMeta({
      title : 'Chauffe Marcel - Entretien de chaudière à vélo',
      meta: {
        description: { name: 'description', content: `Prise de rendez-vous 24h/24. Fini les camionnettes et la pollution, nous sommes C02 neutral chez Chauffe Marcel.` },
      }
    })

  }
}
</script>