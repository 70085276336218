<template>
  <div class="intro section">
    <div class="wrap flex column content-start items-start justify-start">
      <img src="~Assets/chauffe-marcel_logo.svg">
      <div class="title-wrap">
        <h1>
          Entreprise bruxelloise <u>d'entretien de chaudières</u> à vélo.
        </h1>
      </div>
      <div class="intro-btns flex row">
        <q-btn to="/demander-un-entretien" color="primary" label="Demander un entretien" />
        <q-btn href="#faqlist-3" color="accent" label="Jusqu'à -30% si vous vous regroupez" @click="open_group" />
      </div>     
    </div>
  </div>
</template>

<style>

</style>

<style scoped>
  .intro{
    min-height: 100vh;
    padding: 0;
    background: center / cover no-repeat url('~Assets/chauffe-marcel_background-cover-desktop-2.jpg') ;
  }
  .intro .wrap{
    min-height: 100vh;
    padding: 10vh 30px 10vh 150px;
  }
  img{
    max-width: 80%;
  }
  .title-wrap{
    margin-top: 50px;
    max-width: 720px;
  }
  h1{
    color: #FFFFFF;
    font-size: 4rem;
    font-weight: 600;
    line-height: 4.3rem;
  }
  h1 span{
    font-size: 50%;
    line-height: 100%;
  }

  .intro-btns > * {
    margin-right: 16px;
    margin-bottom: 16px;
    border-radius: 100px;
  }

  @media (orientation: portrait) {
    .intro .wrap{
      justify-content: start;
      padding: 10vh 30px 10vh 30px;
    }
    h1{
      color: #FFFFFF;
      font-size: 3rem;
      font-weight: 900;
      line-height: 3rem;
    }
    .title-wrap{
      margin-top: auto;
      margin-bottom: 50px;
    }
  }
</style>

<script>
export default {
  methods : {
    open_group : function () {
      let group = this.$parent.faqlist[3]
      console.log('open_group',group);
      group[0].show()
    }
  }
}
</script>