<template>
  <q-page class="Demander_un_entretien flex flex-center column">
    <div class="section">
      <div class="wrap flex column">
        <router-link to="./"><img src="~Assets/chauffe-marcel_logo.svg"></router-link>

        <div class="demande-steps flex column justify-start content-center">
          <q-carousel 
            animated 
            transition-prev="slide-right"
            transition-next="slide-left"
            class="carousel" v-model="slide">
            <q-carousel-slide name="step-1">
              <div>
                <h2>Vous êtes ?</h2>
                <q-btn outline class="btn" label="Particulier" @click="step(2, $event)"/><br>
                <q-btn outline class="btn" label="Professionnel" @click="step(2, $event)"/>
              </div> 
            </q-carousel-slide>

            <q-carousel-slide name="step-2">
              <div>
                <h2>À quelle fréquence souhaitez-vous faire entretenir votre chaudière GAZ ?</h2>
                <q-btn outline class="btn" label="Chaque année" @click="step(3, $event)"/><br>
                <q-btn outline class="btn" label="Tous les deux ans" @click="step(3, $event)"/>
              </div> 
            </q-carousel-slide>

            <q-carousel-slide name="step-3">
              <div>
                <h2>Immeuble de +10 ans ?</h2>
                <p>Si votre bâtiment à été construit il y à plus de 10 ans vous bénéficiez du taux de TVA réduit (6%)</p>
                <q-btn outline class="btn" label="Oui" @click="step(4, $event)"/><br>
                <q-btn outline class="btn" label="Non" @click="step(4, $event)"/>
              </div> 
            </q-carousel-slide>

            <q-carousel-slide name="step-4">
              <div>
                <h2>Prix de l'intervention</h2>
                <div class="btn price">{{price}} <span>tvac</span></div><br>
                <q-btn class="valid-price" color="primary" label="Commander l'intervention" @click="step(5, $event)"/>
              </div> 
            </q-carousel-slide>

            <q-carousel-slide name="step-5">
              <div>
                <h2>Vos coordonnées</h2>
                <q-form
                      v-if="!success && !error"
                      @submit="onSubmit"
                      class="form"
                    >
                    <fieldset :disabled="disabled">
                      <q-input
                        class="field"
                        v-model="fields.lastname"
                        label="Nom *"
                        lazy-rules
                        :rules="[ val => val && val.length > 0 || 'Entrez votre nom']"
                      />

                      <q-input
                        class="field"
                        v-model="fields.firstname"
                        label="Prénom *"
                        lazy-rules
                        :rules="[ val => val && val.length > 0 || 'Entrez votre prénom']"
                      />

                      <q-input
                        v-if="fields.who == 'Professionnel'"
                        class="field"
                        v-model="fields.company"
                        label="Nom de la société*"
                        lazy-rules
                        :rules="[ val => val && val.length > 0 || 'Entrez le nom de la société']"
                      />

                      <q-input
                        v-if="fields.who == 'Professionnel'"
                        class="field"
                        v-model="fields.vat"
                        label="Numéro de TVA *"
                        lazy-rules
                        :rules="[ val => val && val.length > 0 || 'Entrez un numéro de TVA valide']"
                      />

                      <q-input
                        class="field address"
                        v-model="fields.address"
                        label="Adresse d’intervention * (Seulement à Bruxelles)"
                        lazy-rules
                        :rules="[ val => val && val.length > 0 || 'Entrez une adresse d\'intervention']"
                      />

                      <q-input
                        class="field cp"
                        v-model="fields.cp"
                        label="Code postal"
                        type="number"
                        min="0" max="9000"
                        lazy-rules
                        :rules="[ val=>check_cp_bxl(val) || 'Entrez un code postal à Bruxelles']"
                      />

                      <q-input
                        class="field address"
                        v-if="fields.who == 'Professionnel'"
                        v-model="fields.address_invoice"
                        label="Adresse de facturation *"
                        lazy-rules
                        :rules="[ val => val && val.length > 0 || 'Entrez une adresse de facturation']"
                      />

                      <q-input
                        class="field cp"
                        v-if="fields.who == 'Professionnel'"
                        v-model="fields.cp_invoice"
                        label="Code postal"
                        type="number"
                        min="0" max="9999"
                        lazy-rules
                        :rules="[ val => val && val.length > 0 || 'Entrez un code postal en Belgique']"
                      />



                      <q-input
                        class="field"
                        v-model="fields.phone"
                        type="tel"
                        label="Téléphone *"
                        lazy-rules
                        :rules="[ val => val && val.length > 0 || 'Entrez un numéro de téléphone']"
                      />

                      <q-input
                        class="field"
                        v-model="fields.mail"
                        type="email"
                        label="Email *"
                        lazy-rules
                        :rules="[ val => val && val.length > 0 || 'Entrez une adresse email']"
                      />

                      <br>

                      <q-btn class="send" label="Passer au paiement" type="submit" color="primary"/>
                      
                      <p class="text-caption text-left" style="padding: 0px 16px">*Si vous habitez en périphérie Bruxelloise veuillez nous contacter via <a class="text-primary" href="./#contact">ce formulaire</a> ou via <a class="text-primary" href="mailto:info@chauffemarcel.be">info@chauffemarcel.be</a></p>
                    </fieldset>
                </q-form>
              </div> 
            </q-carousel-slide>

          </q-carousel>
          
          <a class="back" href="#" v-if="slide_index > 1" @click="back_to_prev()">
            Retour à l'étape précédente
          </a>
          <router-link v-if="slide_index == 1" class="back" to="./">
            Retour au site
          </router-link>
        </div>

      </div>
    </div>
  </q-page>
</template>

<style scoped lang="scss">
  @import '~Styles/quasar.variables.scss';

  .Demander_un_entretien{
    background-color: #145236;
  }
  img{
    max-width: 100%;
    width: 200px;
  }
  .wrap{
    height: 100%;
    width: 950px;
    max-width: 100%;
  }
  .demande-steps{
    background-color: white;
    margin-top: 40px;
    padding: 30px;
    text-align: center;
    flex: 1;
    align-self: stretch;
  }
  .carousel{
    height: auto;
    min-height: 400px;
    width: 100%;
  }
  h2{
    margin-bottom: 5px;
  }
  .btn{
    color: $primary;
    width: 100%;
    max-width: 290px;
    margin-top: 30px;
  }
  .price{
    font-size: 1.8rem;
    width: 350px;
    max-width: 100%;
    display: inline-block;
    text-align: center;
    border: solid 1px $primary;
    padding: 8px 50px;
    border-radius: 25px;
  }
  .price span{
    font-size: 0.8rem;
  }
  .valid-price{
    width: 350px;
    max-width: 100%;
    margin-top: 30px;
  }
  fieldset {
    padding: 0;
    border: 0;
  }
  .field{
    display: inline-block;
    width: 46%;
    margin: 0px 2%;
  }
  .cp{
    width: 23%;
  }
  .address{
    width: 69%
  }
  .send{
    margin: 30px 0px;
  }
  .back{
    font-size: 1rem;
    color: $primary;
    margin-top: auto;
  }

  @media only screen and (max-width: 500px) {
    h2{
      font-size: 2rem;
      line-height: 2rem;
    }
    .demande-steps{
      padding: 30px 15px 30px 15px;
    }
    .price, .valid-price{
      width: 100%;
    }
  }
</style>

<script>
  import { useMeta } from 'quasar'

  const cps = [
  '1000',
  '1005',
  '1006',
  '1007',
  '1008',
  '1009',
  '1011',
  '1012',
  '1020',
  '1030',
  '1031',
  '1033',
  '1035',
  '1040',
  '1041',
  '1043',
  '1044',
  '1046',
  '1047',
  '1048',
  '1049',
  '1050',
  '1060',
  '1070',
  '1080',
  '1081',
  '1082',
  '1083',
  '1090',
  '1099',
  '1100',
  '1101',
  '1105',
  '1110',
  '1120',
  '1130',
  '1140',
  '1150',
  '1160',
  '1170',
  '1180',
  '1190',
  '1200',
  '1210',
  '1212',
  ]

export default {
  data(){
    return {
      slide: "step-1",
      slide_index: 1,
      result: [
        'who',
        'frequency',
        'more_10',
      ],
      success: false,
      error: false,
      disabled: false,
      fields : {
        who: '',
        frequency: '',
        more_10: '',
        lastname: '',
        firstname : '',
        company: '',
        vat: '',
        address: '',
        cp: '',
        address_invoice: '',
        cp_invoice: '',
        phone: '',
        mail: ''
      },
      price : '170€'
    }
  },
  setup (){

    useMeta({
      title : 'Chauffe Marcel - Demander un entretien',
      meta: {
        description: { name: 'description', content: `Complétez notre formulaire de demande d'entretien de chaudière en ligne. Nous vous contactons dans les 3 jours ouvrables.` },
      }
    })
  },
  methods : {
    step : function(index, event){
      var result_key = this.result[ index-2 ]
      var text = event.target.innerText
      if (result_key) { this.fields[result_key] = text}

      if(result_key == 'more_10' && text == 'Oui') { this.price = "150€" }

      this.slide = "step-"+index
      this.slide_index = index
    },
    back_to_prev: function() {
      this.slide_index = this.slide_index - 1
      this.slide = "step-"+this.slide_index
    },
    onSubmit : function(){
      this.disabled = true

      this.$wp.order = this.$wp.registerRoute( 'chauffeMarcel/v2', '/order/(?P<send>)' );

      this.$wp.order().send( JSON.stringify( this.fields ) )
        .then( ( response_json ) => { 
          var response = JSON.parse( response_json )
          if( !response.error )
          {
            window.location = response.order_url
          }
      })
    },
    check_cp_bxl : function(val) {
      if( cps.includes(val) )
      {
        return true;
      }
      else
      {
        return 'Entrez un code postal à Bruxelles'
      }
      //console.log('check_cp_bxl',val);
      
    }
  }
}
</script>