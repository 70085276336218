<template>
  <div id="faq" class="faq section">
    <div class="wrap">
      <h2>Questions <span>&</span> Réponses</h2>
      <q-list >
        <q-expansion-item group="faq" v-for="(item, key) in faq" :key="key" :ref="'faqlist-'+key" :id="'faqlist-'+key">
          <template v-slot:header>
            {{item.label}}<div class="plus">+</div>
          </template>
          <q-card>
            <q-card-section><p class="content" v-html="item.content"></p></q-card-section>
          </q-card>
        </q-expansion-item>
      </q-list>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import '~Styles/quasar.variables.scss';

  .section{
    background-color: $bg-grey;
  }

  .plus{
    margin-left: auto;
    margin-top: -4px;
    font-size: 42px;
    font-weight: lighter;
    color: $primary;
  }

  .content{
    font-size: 1rem;
    font-weight: 300;
  }
</style>
  
<style>
  .faq .q-expansion-item__toggle-icon{
    display: none;
  }

  .faq .q-expansion-item{
    max-width: 600px;
    margin: auto;
    margin-bottom: 30px;
  }

  .faq .q-item{
    background-color: white;
  }

  .faq .q-focus-helper{
    display: none !important;
  }
</style>
<script>
  export default{
    mounted (){
      this.$parent.faqlist = []
      for (var i = this.faq.length - 1; i >= 0; i--) {
        
        this.$parent.faqlist[i] = this.$refs['faqlist-'+i]
      }
       
    },
    data (){
      return {
        
        faq : [
          {
            label : 'Pourquoi entretenir ma chaudière ?',
            content: "Une chaudière bien entretenue consomme 10 à 12% d’énergie en moins ce qui représente une centaine d'euros par an. Cela rallonge également la durée de vie de votre chaudière."
          },
          {
            label : 'Suis-je obligé de faire l’entretien de ma chaudière ?',
            content: "Oui depuis le 1er janvier 2019, les chaudières à gaz doivent obligatoirement être entretenues au minimum tous les deux ans. Si un accident survient à cause de votre chaudière et que vous n'avez pas d'attestation de contrôle periodique, votre assureur ne vous couvrira pas."
          },
          {
            label : 'Cette obligation tient-elle aussi si je suis locataire ?',
            content: "Et oui, l’entretien de la chaudière est la responsabilité du locataire. Si la chaudière tombe en panne et que l’entretien n’a pas été fait, les frais seront à charge du locataire."
          },
          {
            label : "Quel est l'avantage de se regrouper ?",
            content: `Nos prix diminuent si nous avons plusieurs entretiens dans le même quartier, prenez contact avec vos voisins et bénéficiez de belles réductions sur le prix de votre entretien.<br><br><br>
3 entretiens dans le même quartier: 10% de réduction (135€ TVAC au lieu de 150€ TVAC)*<br><br>
5 entretiens dans le même quartier: 20% de réduction (120€ TVAC au lieu de 150€ TVAC)*<br><br>
10 entretiens dans le même quartier: 30% de réduction (105€ TVAC au lieu de 150€ TVAC)*<br><br>
*Pour un immeuble de plus de 10 ans bénéficiant du taux de TVA réduit (6%)
<br><br><br>
Syndic, colocations, immeubles à appartements, il est temps de faire des économies ! Utilisez le formulaire de contact ci-dessous pour savoir si vous rentrez dans les conditions.`
          },
          {
            label : 'Après combien de temps serai-je contacté par un technicien une fois le rendez-vous demandé ?',
            content: "Nos techniciens vous contactent dans les 3 jours ouvrables et déterminent avec vous une heure et un jour de passage."
          },
          /*{
            label : 'Pourquoi les techniciens de Chauffe Marcel se déplacent à vélo ?',
            content: "En un an, pour chaque employé de Chauffe Marcel utiliser un vélo plutôt qu’une camionnette réduit de 5.2 tonnes les émissions de CO2 dans Bruxelles !"
          },*/
          {
            label : 'Toutes les chaudières peuvent-elles être entretenues ?',
            content: "Chauffe Marcel ne s’occupe que des entretiens des chaudières à gaz et de moins de 100 kW."
          },
          {
            label : 'Pourquoi le prix change en fonction de l\'âge de mon bâtiment ?',
            content: "Si votre bâtiment à plus de 10 ans, la TVA appliquée sera de 6%, s’il a moins de 10 ans le taux sera de 21%. Le coût d’un entretien dans un immeuble de moins de 10 ans passe donc de 150€ TVAC à 170€ TVAC."
          },
        ]
      }
    }
  }
</script>