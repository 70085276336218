<template>
  <div class="cta section">
    <div class="wrap">
      <h2>Réservez dès maintenant un entretien pour votre chaudière au GAZ</h2>
      <div class="bottom-bt">
        <q-btn to="/demander-un-entretien" class="bt" color="primary" label="Commander l'intervention" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import '~Styles/quasar.variables.scss';

  .section{
    background-color: $primary;
  } 

  h2 {
    color: white;
  }

  .bottom-bt {
    text-align: center;
  }

  .bt {
    border: solid 1px white;
  }

  @media only screen and (max-width: 635px) {
    .bt {
      padding-left: 5px !important;
      padding-right: 5px !important;
      width: 100%;
    }

    h2 {
      text-align: left;
    }

  }
</style>

<script>
  export default{

  }
</script>